<template>
  <v-container fluid>
    <v-card>
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'card-header']">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('analytics.lang_salesByProducer') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">
          <b-dropdown no-caret right toggle-class="btn-icon btn-icon-only" :menu-class="[this.$vuetify.theme.dark? 'dark-bg' : '']" variant="link">
            <span slot="button-content"><i class="pe-7s-menu btn-icon-wrapper"></i></span>
            <div>
              <h6 class="dropdown-header" tabindex="-1">
                {{ $t('generic.lang_Options') }}</h6>

              <button :class="[this.$vuetify.theme.dark? 'white--text' : '', 'dropdown-item']" tabindex="0" type="button" @click="exportExcel">
                <font-awesome-icon class="dropdown-icon success--text"
                                   icon="file-excel"/>
                <span>Excel</span>
              </button>
            </div>
          </b-dropdown>
        </div>
      </v-card-title>
      <v-card-text class="mt-6">
        <v-row align="center" justify="center">

          <v-col class="mt-0 pt-0" cols="12" sm="6" >
            <BaseDateInput dense :label="$t('generic.lang_from')" outlined type="date" v-model="start"/>
          </v-col>

          <v-col class="mt-0 pt-0" cols="12" sm="6" >
            <BaseDateInput dense :label="$t('generic.lang_till')" outlined type="date" v-model="end"/>
          </v-col>

          <v-col  class="mt-0 pt-0" cols="12" sm="5" >
            <v-autocomplete clearable hide-details outlined dense flat class="mx-1" auto-select-first
                            :items="itemgroups" item-text="name" chips
                            item-value="id" :label="$t('generic.lang_waregroup')"
                            v-model="wareGroupID"/>
          </v-col>

          <v-col  class="mt-0 pt-0" cols="12" sm="5" >
            <v-autocomplete clearable hide-details outlined dense flat class="mx-1"
                            :items="producers" item-text="producer_name" :label="$t('erp.lang_ware_producer_name')"
                      item-value="id" :rules="[v=> !!v || 'producer is required']"
                      v-model="selectedProducer"/>
          </v-col>
          <v-col class="pb-0 mb-0" cols="4" sm="2">
            <v-select :items="limits" dense :label="$t('accounting.lang_numberOfResults')" outlined v-model="limit" item-text="name" item-value="limit"/>
          </v-col>

          <v-col class="mt-0 pt-0" cols="12">
            <v-btn :disabled="this.loading" :loading="this.loading" @click="getAnalytics()" block
                   class="bg-primary text-light mx-auto">
              <v-icon class="ma-1">remove_red_eye</v-icon>
              {{this.$t('generic.lang_display')}}
            </v-btn>
          </v-col>
          <v-col class="pa-0 ma-0" cols="12">
            <v-divider class="pa-0 ma-0"/>
          </v-col>
          <v-col cols="12">
            <v-card v-if="this.dataToShow&&this.dataToShow.length">
              <v-card-text class="pa-0">
                <v-data-table
                    :items="dataToShow"
                    :headers="dataTableHeaders"
                    :loading="loading"
                >
                  <template v-slot:item.soldQTY="{item}">
                    {{Number(item.soldQTY).toFixed(2)}}
                  </template>

                  <template v-slot:item.buyPriceNetPerItem="{item}">
                    {{item.buyPriceNetPerItem | currency}}
                  </template>

                  <template v-slot:item.buyPriceNetSum="{item}">
                    {{item.buyPriceNetSum | currency}}
                  </template>

                  <template v-slot:item.totalPricePerItem="{item}">
                    {{item.totalPricePerItem | currency}}
                  </template>

                  <template v-slot:item.totalPriceSum="{item}">
                    {{item.totalPriceSum | currency}}
                  </template>


                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import DatePicker from 'vue2-datepicker'
import VueApexCharts from 'vue-apexcharts'
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFileExcel,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {ENDPOINTS} from "@/config";
import moment from "moment";
import {mapState} from "vuex";
import {Events} from "@/plugins/events";
import FileSaver from 'file-saver';
import BaseDateInput from "@/components/common/BaseDateInput.vue";

library.add(
    faFileExcel
);

var self=null;

export default {
  name: "ManufacturerAnalysisComponent",
  components: {
    'apexchart': VueApexCharts,
    DatePicker,
    'font-awesome-icon': FontAwesomeIcon,
    BaseDateInput
  },
  data() {
    return {
      start: "",
      end: "",
      limit:10,
      loading: false,
      dataToShow:[],
      producers:[],
      selectedProducer:null,
      loadingProducers:false,
      wareGroupID:null,
      limits:[
        {
          name:'10',
          limit:10,
        },
        {
          name:'25',
          limit:25,
        },
        {
          name:'50',
          limit:50,
        },
        {
          name:'100',
          limit:100,
        },
        {
          name: this.$t('generic.lang_all'),
          limit:0,
        },
      ],
      itemgroups: []
    }
  },
  computed:{
    ...mapState("cashierIDs",{"cashierIDs":state=>state.availableCashierIDs}),
    ...mapState("api/auth",{"currentID":state=>state.cashierID}),
    dataTableHeaders() {
      return [
        {
          text: this.$t('erp.lang_ware_create_id'),
          value:"itemEAN",
        },
        {
          text: this.$t('generic.lang_name'),
          value:"itemName",
        },
        {
          text: this.$t('generic.lang_waregroup'),
          value:"wareGroupName",
        },
        {
          text: this.$t('erp.lang_nav_manufacturer'),
          value:"producerName",
        },
        {
          text: this.$t('generic.lang_anzahlVK'),
          value:"soldQTY",
        },
        {
          text:this.$t('erp.lang_netPurchasePricePerUnit'),
          value:"buyPriceNetPerItem",
        },
        {
          text: this.$t('analytics.lang_calculatedNetPurchasePrice'),
          value:"buyPriceNetSum",
        },
        {
          text: this.$t('analytics.lang_totalPricePerItem'),
          value:"totalPricePerItem",
        },
        {
          text: this.$t('analytics.lang_totalPriceSum'),
          value:"totalPriceSum",
        },
      ];
    },
  },
  methods: {
    getForm() {
      let form = {};

        form = {
          startDate: this.getFrom(),
          endDate:this.getTo(),
          producerID:parseInt(Number(this.selectedProducer)),
          limit: this.limit,
          itemgroupID:parseInt(Number(this.wareGroupID))
        }

      return form;
    },
    getFrom() {
      let startDate = new Date(this.start);
      return moment.unix(startDate.getTime()/1000).startOf("days").unix();
    },
    getTo(){
      let endDate = new Date(this.end);
      return moment.unix(endDate.getTime()/1000).endOf("days").unix();
    },
    loadProducers(){

      this.loadingProducers=true;

      this.axios.post(ENDPOINTS.ERP.PRODUCERS.GETALL)
          .then(res=>{
            if(res.data.success){
              this.producers=_.orderBy(res.data.producers,'producer_name');
            }else{
              Events.$emit("showSnackbar", {
                message: res.data.msg||res.data.success,
                color: "error"
              });
            }

          }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+'\n'+err.message,
          color: "error"
        });
          }).finally(()=>{
        this.loadingProducers=false;
          })

    },
    getAnalytics(){
      this.loading=true

      this.axios.post(ENDPOINTS.ANALYTICS.WARE.salesByItemgroupProducer,this.getForm())
          .then(res=>{
            if(res.data.success){
              if(res.data.data.length>0){
                this.dataToShow=res.data.data
              }else{
                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_nothingtoshow'),
                  color: "warning"
                });
              }
            }else{
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }

      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+''+err.message,
          color: "error"
        });
      }).finally(()=>{
        this.loading=false;
      })
    },
    exportExcel() {
      this.axios.post('get/analytics/excel/salesByItemgroupProducer/',{
            startDate: this.getFrom(),
            endDate:this.getTo(),
            producerID:parseInt(Number(this.selectedProducer)),
            itemgroupID:parseInt(Number(this.wareGroupID))
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        //DOWNLOAD pdf
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }), "Sales by producer.xlsx");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+'\n'+err.message,
          color: "error"
        });
      }).finally(() => {

      })
    },
  },
  async mounted() {
    this.itemgroups = await this.$store.dispatch("itemgroups/getItemgroups");

    self=this;
    this.loadProducers()
  }
}
</script>

<style>
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  top: 6px !important;
}
</style>