<template>
  <div>
    <page-title :heading="$t('analytics.lang_manufacturerAnalysis')" :icon="icon"
                :subheading="$t('analytics.lang_manufacturerAnalysis')"></page-title>
    <div class="app-main__inner">
      <ManufacturerAnalysisComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import ManufacturerAnalysisComponent
  from "@/components/analytics/warewanlytics/manufacturer/ManufacturerAnalysisComponent";

export default {
  components: {
    ManufacturerAnalysisComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
  })
}
</script>